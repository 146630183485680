import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import styled from '@emotion/styled';
import { CircularProgress, Switch } from '@material-ui/core';
import ModalSlider from '../../components/modal-slider/modal-slider';
import { TextField } from '../../components/TextField';
import Button from '../../components/button';
import { removeMasks } from '../../utils/removeMask';
import { CREATE_PACIENTE_COMERCIAL, UPDATE_PACIENTE_COMERCIAL } from '../../graphql/mutations';
import Notification from '../../components/notification/Notification';
import { FIND_WHITE_LABEL } from '../../graphql/queryes';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

export const ModalCadastroPaciente = ({ isOpen, handleClose, dadosPaciente, atualizarPacienteList }) => {
  const [loadingSave, setLoadingSave] = useState(false)
  const [dadosCadastrais, setDadosCadastrais] = useState({
    ativo: dadosPaciente.id ? dadosPaciente.ativo : true
  })
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const handleDadosCadastrais = (value, field) => {
    setDadosCadastrais((prevState) => {
      return {
        ...prevState,
        [field]: value,
      }
    })
  }
  const [criarPacienteComercial] = useMutation(CREATE_PACIENTE_COMERCIAL)
  const [atualizarPacienteComercial] = useMutation(UPDATE_PACIENTE_COMERCIAL)


  const { data: whitelabelData } = useQuery(FIND_WHITE_LABEL)
  const whitelabel = whitelabelData?.findEntidadeLogada?.whitelabel?.nome
  const isTaip = whitelabel === 'TAIP_SAUDE'

  const checkCampos = () => {
    const { nome, cpf, email, telefone } = dadosCadastrais || {}
    const campoInexistenteAtualizacaoCadastro = !nome || !cpf || !email || !telefone
    return campoInexistenteAtualizacaoCadastro
  }
  const handleClickSave = async () => {

    const campoInexistes = checkCampos()

    if (campoInexistes) {
      setNotification({ isOpen: true, variant: "error", message: "Todos os campos devem ser preenchidos" })
      return
    }

    try {
      setLoadingSave(true)
      const variables = {
        paciente: {
          ...(dadosPaciente?.id ? { id: dadosPaciente?.id } : {}),
          nome: dadosCadastrais.nome,
          email: dadosCadastrais.email,
          cpf: removeMasks(dadosCadastrais.cpf),
          telefonePrincipal: removeMasks(dadosCadastrais.telefone),
          ativo: dadosCadastrais.ativo
        },
      }

      const response = dadosCadastrais?.id ? await atualizarPacienteComercial({ variables: variables }) : await criarPacienteComercial({ variables: variables })
      if(response.data?.updatePacienteComercial?.id || response.data?.createPacienteComercial?.id)
        setNotification({ isOpen: true, variant: "success", message: dadosCadastrais?.id?"Dados atualizados com sucesso!": "Paciente cadastrado com sucesso!"})

    } catch (e) {
      console.error(e)
      setNotification({ isOpen: true, variant: "error", message: "Não foi possível cadastrar o paciente" })

    } finally {
      setLoadingSave(false)
      handleClose()
      atualizarPacienteList()
    }
  }
  useEffect(() => {
    if (dadosPaciente.id) {
      setDadosCadastrais(dadosPaciente)
      return
    }
    setDadosCadastrais({})
  }, [dadosPaciente])

  return (
    <>
      <ModalSlider
        isOpen={isOpen}
        onClose={handleClose}
        title={`${dadosCadastrais?.id ? 'Informações do' : "Novo"} paciente`}
      >
        <Wrapper>
          <Row>
            <TextField
              label="Nome"
              placeholder="Digite o seu nome completo"
              value={dadosCadastrais?.nome}
              onChange={(e) => handleDadosCadastrais(e.target.value, 'nome')}
            />
          </Row>
          <Row>
            <TextField
              label="CPF"
              mask="cpf"
              placeholder="Digite o cpf"
              value={dadosCadastrais?.cpf}
              onChange={(e) => handleDadosCadastrais(e.target.value, 'cpf')}
            />
          </Row>
          <Row>
            <TextField
              label="Telefone"
              mask="phone"
              placeholder="Digite o telefone"
              value={dadosCadastrais?.telefone}
              onChange={(e) => handleDadosCadastrais(e.target.value, 'telefone')}
            />
          </Row>
          <Row>
            <TextField
              label="Email"
              placeholder="Digite o email"
              value={dadosCadastrais?.email}
              onChange={(e) => handleDadosCadastrais(e.target.value, 'email')}
            />
          </Row>
          {isTaip && <Row style={{ alignItems: 'center' }}>
            <AtivoLabel>Ativo</AtivoLabel>
            <Switch checked={dadosCadastrais.ativo} color="primary" onChange={(e) => handleDadosCadastrais(e.target.checked, 'ativo')}> </Switch>
          </Row>}
          <Row>
            <Button disabled={loadingSave} onClick={handleClickSave}>
              Salvar
              {loadingSave && <CircularProgress color="#fff" size={12} />}
            </Button>
          </Row>
        </Wrapper>

      </ModalSlider>
      < Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </>
  )
}

const Row = styled.div`
  display: flex;
  gap: 5px;

`;
const AtivoLabel = styled.span`
 color: rgba(0, 0, 0, 0.54);

`;
const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px;
`
