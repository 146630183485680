import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Flex from '../../components/flex';

const ItemMenu = ({to, title, icon, hidden, onClick}) => {
    const Icon = icon;
    return (
        !hidden && <Container onClick={onClick}>
            <Tooltip title={title} placement="right">
                <LinkStyled to={to} replace>
                    <Icon className="menu-icon" />
                </LinkStyled>
            </Tooltip>
        </Container>
    );
};

const Container = styled(Flex)`
    flex: 1 100%;
    max-height: 8%;
    min-height: 65px;
`;

const LinkStyled = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &:hover {
        background-color: #00b6b3;
    }

    img {
        height: auto;
        width: 1rem;
    }

    & .menu-icon {
        color: #fff;
        font-size: 30px;
    }
`;

export default ItemMenu;
