import { Box } from "@material-ui/core";
import React, { useState } from "react";
import ButtonFloat from "../../components/button/ButtonFloat";
import { withBaseLayout } from "../../layout/base-layout";
import { useQuery } from "react-apollo";
import List from "../../components/list/list";
import styled from "@emotion/styled";
import { FIND_ALL_PACIENTE_COMERCIAL } from "../../graphql/queryes";
import Input from "../../components/input/Input";
import { cpfCnpjFormatted, phoneFormatted } from "../../utils/masks";
import { ModalCadastroPaciente } from "./modalCadastroPaciente";






const pageableDefault = {
    pageNumber: 0,
    pageSize: 30,
    sortDir: "ASC",
    sortField: "nome"
}

const CadastrarPaciente = () => {


    const [openModalNovo, setOpenModalNovo] = useState(false)
    const [dadosPaciente, setDadosPaciente] = useState(false)
    const [searchDTO, setSearchDTO] = useState({
        search: null,
    });

    const { data, loading, error, refetch, fetchMore } = useQuery(FIND_ALL_PACIENTE_COMERCIAL, {
        variables: {
            search: searchDTO.search,
            pageableDto: pageableDefault
        },
    });

    const {findAllPacientes}=data || {}


    const handleClickNovo = () => {
        setOpenModalNovo(true);
    };

    const handleCloseModalNovo = () => {
        setOpenModalNovo(false);
        setDadosPaciente({})
    }



    const handleLoadMore = async (e) => {

        e.preventDefault();
        if (!data?.findAllPacientes?.last) {

            await fetchMore({
                variables: {
                    pageableDto: { ...pageableDefault, pageNumber: data.findAllPacientes.number + 1 },
                    ...searchDTO
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    const findAll = {
                        findAllPacientes: {
                            ...prev.findAllPacientes,
                            ...fetchMoreResult.findAllPacientes,
                            content: [
                                ...prev.findAllPacientes.content,
                                ...fetchMoreResult.findAllPacientes.content
                            ]
                        }
                    }
                    return findAll

                }
            });
        }
    }


    const handleClickPaciente = (e) => {
        try {
            const { telefonePrincipal, ...rest } = e
            setDadosPaciente({ ...rest, telefone: telefonePrincipal })
            setOpenModalNovo(true)

        } catch (e) {
            console.error(e)
        }
    }

    const handleChange = (e) => {
        setSearchDTO({ ...searchDTO, search: e.target.value })
        refetch()
    }


    return (
        <Box>
            <RowFilter>
                <Input
                    onChange={handleChange}
                    classInputRoot={{width:'800px'}}
                />
            </RowFilter>

            <List
                columns={[
                    {
                        title: 'Nome',
                        value: (item) => item.nome
                    },
                    {
                        title: 'CPF',
                        value: (item) => cpfCnpjFormatted(item.cpf) || '',
                    },
                    {
                        title: 'Telefone',
                        value: (item) => phoneFormatted(item.telefonePrincipal) || '',
                    },
                ]}
                items={findAllPacientes?.content || []}
                loading={loading}
                error={error}
                last={data?.findAllPacientes?.last}
                onLoadMore={handleLoadMore}
                onClickItem={handleClickPaciente}
            />
            {<ButtonFloat title="Novo" onClick={handleClickNovo} />}
              <ModalCadastroPaciente
              isOpen={openModalNovo}
              handleClose={handleCloseModalNovo}
              dadosPaciente={dadosPaciente}
              atualizarPacienteList={refetch} />
        </Box>
    )
}

const RowFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  margin: 5px;
`;


export default withBaseLayout(CadastrarPaciente)
